<template>
  <div class="sticky-top">
    <div v-if="this.$route.matched.some(route => route.name.includes('adminlogin'))"></div>
    <div v-else-if="this.$route.matched.some(route => route.name.includes('userregister'))"></div>
    <div v-else-if="this.$route.matched.some(route => route.name.includes('resellerregister'))"></div>
    <div v-else-if="this.$route.matched.some(route => route.name.includes('businessregister'))"></div>
    <div v-else-if="this.$route.matched.some(route => route.name.includes('businesslogin'))"></div>
    <div v-else-if="this.$route.matched.some(route => route.name.includes('userlogin'))"></div>
    <div v-else-if="this.$route.matched.some(route => route.name.includes('resellerlogin'))"></div>
    <div v-else-if="this.$route.matched.some(route => route.name.includes('businesssidebar'))"></div>
    <div v-else-if="this.$route.matched.some(route => route.name.includes('resellersidebar'))"></div>
    <div v-else-if="this.$route.matched.some(route => route.name.includes('usersidebar'))"></div>
    <div v-else-if="this.$route.matched.some(route => route.name.includes('admin'))"></div>
    <div v-else-if="this.$route.matched.some(route => route.name.includes('jobsby'))">
      <div class="text-center mt-4 bg-white">
        <router-link to="/" class="navbar-brand" target="_blank">
          <img src="@/assets/logo.png" alt="logo" width="65">
        </router-link>
      </div>
    </div>
    <div v-else-if="this.$route.matched.some(route => route.name.includes('hotdealsby'))">
      <div class="text-center mt-4 bg-white">
        <router-link to="/" class="navbar-brand" target="_blank">
          <img src="@/assets/logo.png" alt="logo" width="65">
        </router-link>
      </div>
    </div>
    <div v-else-if="this.$route.matched.some(route => route.name.includes('reviewsby'))">
      <div class="text-center mt-4 bg-white">
        <router-link to="/" class="navbar-brand" target="_blank">
          <img src="@/assets/logo.png" alt="logo" width="65">
        </router-link>
      </div>
    </div>
    <div v-else-if="this.$route.matched.some(route => route.name.includes('salesby'))">
      <div class="text-center mt-4 bg-white">
        <router-link to="/" class="navbar-brand" target="_blank">
          <img src="@/assets/logo.png" alt="logo" width="65">
        </router-link>
      </div>
    </div>
    <div v-else>
      <div class="topbar elevation-1" >
        <div class="container py-0">
          <div class="d-flex align-items-center justify-content-md-end">
            <div class="col-3 col-sm-4 d-block d-sm-none px-0 text-start">
              <router-link to="/" class="navbar-brand">
                <img src="@/assets/logo.png" alt="logo" width="50">
              </router-link>
            </div>
            <div class="col-2 col-sm-2 d-block d-sm-none px-0 text-start">
              <v-menu absolute offset-y style="max-width:350px">
                <template v-slot:activator="{ on, attrs }">
                  <v-card class="portrait elevation-0"
                    img="https://bizlx.itechvision.in/images/appload.jpeg"
                    height="32" width="32" v-bind="attrs" v-on="on">
                  </v-card>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in options" :key="index">
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item>
                </v-list>
    </v-menu>
              <!-- <img src="@/assets/appload.jpeg" alt="Download App" width="32" class="img-fluid"> -->
            </div>
            <div class="col-4 col-sm-4 text-md-end d-flex justify-md-end justify-content-md-between d-sm-none px-0" style="justify-content: center;">
              <router-link to="/business/register" class="">
                <v-btn small color="cred">List Business</v-btn>
              </router-link>
            </div>
            <div class="col-3 col-sm-3 d-block me-3 d-md-none text-md-end d-flex justify-end justify-content-md-between px-0">
              <span v-if="getUserlogged === ''">
                <!-- <v-tooltip bottom color="dark" >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="cblu"  v-bind="attrs"
                                v-on="on">mdi-heart-outline
                        </v-icon>
                    </template>
                    <span>Login First</span>
                  </v-tooltip> -->
              </span>
              <span v-else>
              <router-link to="/mywishlist" class="px-1">
                 <v-icon color="red">mdi-heart-outline</v-icon>
               </router-link>
            </span>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-if="getUserlogged === ''" class="cblu" v-bind="attrs" v-on="on">mdi-account-tie</v-icon>
                  <v-icon v-else class="cblu" v-bind="attrs" v-on="on">mdi-account-tie</v-icon>
                </template>
                <v-list class="py-0">
                  <div v-if="getUser === '0'">
                    <v-list-item ><router-link to="/user/profile">User Dashboard</router-link></v-list-item>
                    <v-list-item @click="userLogout">Logout</v-list-item>
                  </div>
                  <div v-else-if="getUser === '1'">
                    <v-list-item ><router-link to="/business/profile">Business Dashboard</router-link></v-list-item>
                    <v-list-item @click="businessLogout">Logout</v-list-item>
                  </div>
                  <div v-else-if="getUser === '2'">
                    <v-list-item ><router-link to="/reseller/business-list">Reseller Dashboard</router-link></v-list-item>
                    <v-list-item @click="resellerLogout">Logout</v-list-item>
                  </div>
                  <div v-else-if="getUser === '3'">
                    <v-list-item ><router-link to="/subadmin/dashboard">SubAdmin Dashboard</router-link></v-list-item>
                    <v-list-item @click="subadminLogout">Logout</v-list-item>
                  </div>
                  <div v-else-if="getUser === '4'">
                    <v-list-item ><router-link to="/admin/dashboard">Admin Dashboard</router-link></v-list-item>
                    <v-list-item @click="adminLogout">Logout</v-list-item>
                  </div>
                  <div v-else>
                    <v-list-item @click="loginPopup">Login</v-list-item>
                  </div>
                </v-list>
              </v-menu>
              <span v-if="getUserlogged === ''">
                 <!-- <v-tooltip bottom color="dark">
                    <template v-slot:activator="{ on, attrs }">
                         <v-badge color="red" content="6">
                            <v-icon class="cblu"  v-bind="attrs"
                                    v-on="on">mdi-bell-outline
                            </v-icon>
                         </v-badge>
                    </template>
                    <span>Login First</span>
                  </v-tooltip> -->
              </span>
              <span v-else class="d-none">
                <router-link to="/mynotification" class="px-1">
                <v-badge color="red" content="6">
                  <v-icon class="cblu" >mdi-bell-outline</v-icon>
                </v-badge>
              </router-link>
              </span>
            </div>
          </div>
          <cat-search  class="d-block d-sm-none pb-2"  v-if="$route.name !=='searchall'"></cat-search>
<!--          <span -->
<!--              v-if="$route.name !=='jobsbycity' &&-->
<!--                      $route.name !=='allcityjobs' &&-->
<!--                      $route.name !=='jobdetail' &&-->
<!--                      $route.name !=='allcatjobs' &&-->
<!--                      $route.name !=='allcategory' &&-->
<!--                      $route.name !=='jobsearchpage' &&-->
<!--                      $route.name !=='jobsbycatcity'"-->
<!--          >-->
<!--            <cat-search  class="d-block d-sm-none pb-2"></cat-search>-->
<!--          </span>-->
<!--          <span v-else>-->
<!--          <job-search  class="d-block d-sm-none pb-2"></job-search>-->
<!--        </span>-->
        </div>
      </div>
      <nav class="navbar navbar-expand-lg bg-white py-0 shadow-sm d-none d-md-block">
        <div class="container py-0">
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <div class="col-md-2">
              <router-link to="/" class="navbar-brand">
                <img src="@/assets/logo.png" alt="logo" width="75">
              </router-link>
            </div>
            <div class="col-md-8">
              <div class="mx-auto forms">
                <cat-search>
                </cat-search>
<!--                <job-search v-else></job-search>-->
              </div>
            </div>
            <div class="col-md-2">
              <div class="ms-auto text-end listbtncls">
                <router-link to="/business/register" class="listbbtn">
                    <v-btn small color="cred">List Business</v-btn>
                  </router-link>
                <span v-if="getUserlogged === ''">
                  <!-- <v-tooltip bottom color="dark">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="cblu"  v-bind="attrs"
                              v-on="on">mdi-heart-outline
                      </v-icon>
                    </template>
                    <span>Login First</span>
                  </v-tooltip> -->
                </span>
                <span v-else>
                  <router-link v-if="getUser === '0'" to="/user/wishlist" class="px-1">
                     <v-icon color="red">mdi-heart-outline</v-icon>
                   </router-link>
                   <router-link v-if="getUser === '1'" to="/business/wishlist" class="px-1">
                     <v-icon color="red">mdi-heart-outline</v-icon>
                   </router-link>
                   <router-link v-if="getUser === '2'" to="/reseller/wishlist" class="px-1">
                     <v-icon color="red">mdi-heart-outline</v-icon>
                   </router-link>
                </span>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="cblu" v-bind="attrs" v-on="on">mdi-account-tie</v-icon>
                  </template>
                  <v-list class="py-1">
                    <div v-if="getUser === '0'">
                      <v-list-item ><router-link to="/user/profile">User Dashboard</router-link></v-list-item>
                      <v-list-item @click="userLogout">Logout</v-list-item>
                    </div>
                    <div v-else-if="getUser === '1'">
                      <v-list-item ><router-link to="/business/profile">Business Dashboard</router-link></v-list-item>
                      <v-list-item @click="businessLogout">Logout</v-list-item>
                    </div>
                    <div v-else-if="getUser === '2'">
                      <v-list-item ><router-link to="/reseller/business-list">Reseller Dashboard</router-link></v-list-item>
                      <v-list-item @click="resellerLogout">Logout</v-list-item>
                    </div>
                    <div v-else-if="getUser === '3'">
                      <v-list-item ><router-link to="/admin/dashboard">SubAdmin Dashboard</router-link></v-list-item>
                      <v-list-item @click="subadminLogout">Logout</v-list-item>
                    </div>
                    <div v-else-if="getUser === '4'">
                      <v-list-item ><router-link to="/admin/dashboard">Admin Dashboard</router-link></v-list-item>
                      <v-list-item @click="adminLogout">Logout</v-list-item>
                    </div>
                    <div v-else>
                      <v-list-item @click="loginPopup">Login</v-list-item>
                    </div>
                  </v-list>
                </v-menu>
                <span v-if="getUserlogged === ''">
                  <!-- <v-tooltip bottom color="dark">
                    <template v-slot:activator="{ on, attrs }">
                       <v-badge color="red" content="6">
                          <v-icon class="cblu"  v-bind="attrs"
                                  v-on="on">mdi-bell-outline
                          </v-icon>
                       </v-badge>
                    </template>
                    <span>Login First</span>
                  </v-tooltip> -->
              </span>
                <span v-else class="d-none">
                  <router-link to="/mynotification" class="px-1">
                    <v-badge color="red" content="6">
                      <v-icon class="cblu" >mdi-bell-outline</v-icon>
                    </v-badge>
                </router-link>
              </span>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
    <v-dialog v-model="loginDialog" max-width="300px" content-class="rounded-xl">
      <v-card>
        <v-card-text>
          <div class="mb-0 d-flex align-items-center">
            <v-col sm="11" cols="11" class="text-center h3 pb-0 text-dark">Login As</v-col>
            <v-col sm="1" cols="1" class="pb-0">
              <v-icon class="float-end" @click="closeDialog">mdi-close</v-icon>
            </v-col>
          </div>
          <v-row class="align-items-center justify-content-between">
            <v-col class="py-0">
              <v-radio-group v-model="selectedLogin" class="mt-0" hide-details>
                <v-radio
                  v-for="n in logintype"
                  :key="n.id"
                  :label="n.name"
                  hide-details
                  :value="n.id"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col class="text-end py-0">
              <v-btn class="go" color="cred" small @click="goToRoute">Go</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
  </v-dialog>
  </div>
</template>
<script>
import CatSearch from "@/views/search/CatSearch";
// import JobSearch from "@/views/search/JobSearch";

export default {
  name: "AdminBar",
  components: {CatSearch},

  data() {
    return {
      drawer: false,
      group: null,
      token: '',
      user: '',
      loginDialog: false,
      selectedLogin: 1,
      logintype:[{id:1,name:"Public"},{id:2,name:"Business"},{id:3,name:"Reseller"}],
      loggedUser: [],
      options:[{name:"Android"},{name:"IOS"}]
    }
  },
  computed: {
    getUser(){
      return this.$store.state.userData.user_role;
    },
    getUserlogged(){
      return this.$store.state.userData;
    }
  },
  watch: {
    group () {
      this.drawer = false
    },
  },
  methods: {
    userLogout() {
      this.$store.dispatch('logout');
      this.$router.push('/user/login');
    },
    businessLogout() {
      this.$store.dispatch('logout');
      this.$router.push('/business/login');
    },
    resellerLogout() {
      this.$store.dispatch('logout');
      this.$router.push('/reseller/login');
    },
    subadminLogout() {
      this.$store.dispatch('logout');
      this.$router.push('/subadmin/login');
    },
    adminLogout() {
      this.$store.dispatch('logout');
      this.$router.push('/admin/login');
    },
    loginPopup(){
      this.loginDialog = true;
    },
    closeDialog(){
      this.loginDialog = false;
    },
    goToRoute() {
      if (this.selectedLogin == 1) {
        // Navigate to route for selectedLogin 1
        this.$router.push({ name: 'userlogin' });
      } else if (this.selectedLogin == 2) {
        // Navigate to route for selectedLogin 2
        this.$router.push({ name: 'businesslogin' });
      } else if (this.selectedLogin == 3) {
        // Navigate to route for selectedLogin 3
        this.$router.push({ name: 'resellerlogin' });
      }
      this.loginDialog = false;
    }
  }
}
</script>
<style scoped>
.topbar{background-color: #fff;}
.v-list-item {
  min-height: 30px !important;
  }
  .listbtncls{
    display: flex;
    justify-content: space-around;
  }
</style>