<template>
  <div>
    <carousel v-if="slides.length > 0" :autoplay="false" :margin="10" :nav="true"
              :navText="['‹','›']"
              :responsive = "{0:{items:2},576:{items:2},768:{items:2},1200:{items:4}}"
              :dots="false" class="city mobile">
      <div class="item" v-for="city in slides" :key="city.id">
        <v-img :src=(url+city.city_img_url) class="rounded-3" height="150px"
               gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)">
                <span class="listings position-absolute">
                  <span>
                    <v-btn small color="cred" @click="sendCitylist(city.city_id,city.city)">Listings
                      <span class="badge bg-white text-black ms-1">{{ city.listcount }}</span>
                    </v-btn>
                  </span>
                </span>
          <span class="deals position-absolute">
             <div v-if="city.dealcount !== 0">
               <span>
                 <v-btn small color="cred" @click="sendCityid(city.city_id,city.city)">Deals
                   <span class="badge bg-white text-black ms-1">{{ city.dealcount }}</span>
                 </v-btn>
               </span>
             </div>
             <div v-else>
              <v-btn small title="No Deals" color="error">No Deals</v-btn>
            </div>
          </span>
        </v-img>
        <div class="d-flex my-2 small justify-content-between">
          <div class="fw-semibold">{{city.city}}</div>
          <!-- <div class="fw-semibold cred">7.8 km
            <span class="ms-1">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"><path fill="#fd0606" d="M1.946 9.316c-.522-.175-.526-.456.011-.635L21.043 2.32c.529-.176.832.12.684.638l-5.453 19.086c-.151.529-.456.547-.68.045L12 14l6-8l-8 6l-8.054-2.684Z"/>
              </svg>
            </span>
          </div> -->
        </div>
      </div>
    </carousel>
    <carousel v-if="slides.length > 0" :margin="10"
        :navText="['‹','›']"
        :responsive = "{0:{items:2},576:{items:2},768:{items:2.3},992:{items:3},1200:{items:4}}"
        :dots="false" class="city desktop">
          <div class="item" v-for="city in slides" :key="city.id">
            <v-img :src=(url+city.city_img_url)
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" class="rounded">
                <span class="listings position-absolute">
                  <span>
                    <v-btn small color="error" @click="sendCitylist(city.city_id,city.city)">Listings
                      <span class="badge bg-white text-black ms-1">{{ city.listcount }}</span>
                    </v-btn>
                  </span>
                </span>
                <span class="deals position-absolute">
                  <div v-if="city.dealcount !== 0">
                    <span>
                      <v-btn small color="error" @click="sendCityid(city.city_id,city.city)">Deals
                        <span class="badge bg-white text-black ms-1">{{ city.dealcount}}</span>
                      </v-btn>
                  </span>
                  </div>
                  <div v-else>
                    <v-btn small title="No Deals" color="error">No Deals</v-btn>
                  </div>
                </span>
                <span class="place position-absolute">
                  <v-btn block color="transparent" class="text-white fw-bolder">{{ city.city }}</v-btn>
                </span>
            </v-img>
          </div>
    </carousel>
  </div>
</template>
<script>
import carousel from "vue-owl-carousel";
import axios from "axios";
export default {
  name: "HomeCitydeals",
  components:{carousel},
  data: () => ({
    model: null,
    url:'https://bizlx.itechvision.in',
    slides: []
  }),
  created() {
    this.allCitydeals();
  },
  methods:{
    allCitydeals(){
      axios.get('home/citydeal')
          .then((resp)=>{
            this.slides = resp.data.citydeals;
          });
    },
    sendCitylist(cid,city){
      const dealtype = 3;
      this.$store.dispatch('setdealtypeid',{dealtype});
      const city_id = cid;
      this.$store.dispatch('setcityid',{city_id});
      const subcat_id = '';
      this.$store.dispatch('setsubcatid',{subcat_id});
      this.$router.push({name:'searchall',params:{subcat_name:'',city_name:city}});
      console.log(dealtype);
    },
    sendCityid(cityid,city){
      const dealtype = 0;
      this.$store.dispatch('setdealtypeid',{dealtype});
      const city_id = cityid;
      this.$store.dispatch('setcityid',{city_id});
      const subcat_id = '';
      this.$store.dispatch('setsubcatid',{subcat_id});
      this.$router.push({name:'searchall',params:{subcat_name:'',city_name:city}});
    }
  }
}
</script>

<style scoped>
.listings {top: 5px;left: 5px;}
.deals {top: 5px;right: 5px;}
.place {bottom: 0;width: 100%;}
.v-image__image.v-image__image--cover {
  background-color: rgba(0,0,0,0.5);
  background-blend-mode: overlay;
}
@media (max-width: 576px){
  .city .v-btn.v-size--small {
    font-size: 0.60rem;
    text-transform: capitalize;
    padding: 0 6px;
  }
  .desktop{display: none;}
}
@media (min-width: 576.1px) {
  .mobile{display: none;}
}

</style>