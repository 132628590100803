import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
 const store = new Vuex.Store({
    state: {
        userToken: '', // User token
        userData: '', // User data
        userId: '', // User data
        isAuthenticated: false,
        userSearch:'',
        listingradius: 20,
        country_id:1,
        city_id:'',
        subcat_id:'',
        jobcat_id:'',
        dealtype:3,
        searchy:'',
    },
    mutations: {
        SET_USER_TOKEN(state, token) {
            state.userToken = token;
            localStorage.setItem('userToken', token);
            state.isAuthenticated = true;
        },
        SET_USER_DATA(state, userData) {
            state.userData = userData;
            localStorage.setItem('userData', JSON.stringify(userData));
        },
        SET_USER_ID(state, userId) {
            state.userId = userId;
            localStorage.setItem('userId', JSON.stringify(userId));
        },
        SET_CITY_ID(state, city_id) {
            state.city_id = city_id;
            localStorage.setItem('city_id', JSON.stringify(city_id));
        },
        SET_SUBCAT_ID(state, subcat_id) {
            state.subcat_id = subcat_id;
            localStorage.setItem('subcat_id', JSON.stringify(subcat_id));
        },
        SET_JOBCAT_ID(state, jobcat_id) {
            state.jobcat_id = jobcat_id;
            localStorage.setItem('jobcat_id', JSON.stringify(jobcat_id));
        },
        SET_DEALTYPE_ID(state, dealtype) {
            state.dealtype = dealtype;
            localStorage.setItem('dealtype', JSON.stringify(dealtype));
        },
        SET_SEARCHY(state,searchy) {
            state.searchy = searchy;
            localStorage.setItem('searchy',JSON.stringify(searchy));
        },
        SET_USER_SEARCH(state, userSearch) {
            state.userSearch = userSearch;
            localStorage.setItem('userSearch',JSON.stringify(userSearch))
        },
        CLEAR_USER(state) {
            state.userToken = '';
            state.userData = '';
            localStorage.clear('userToken'); // Remove userToken from local storage
            localStorage.clear('userData'); // Remove userData from local storage
        },
        CLEAR_ID(state) {
            state.userId = '';
            localStorage.clear('userId'); // Remove userToken from local storage
        },
    },
    actions: {
        login({ commit }, { token, userData }) {
            commit('SET_USER_TOKEN', token);
            commit('SET_USER_DATA', userData);

        },
        setuserid({ commit }, { userId }){
            commit('SET_USER_ID', userId);
        },
        setcityid({commit},{city_id}){
            commit('SET_CITY_ID', city_id);
        },
        setsubcatid({commit},{subcat_id}){
            commit('SET_SUBCAT_ID', subcat_id);
        },
        setjobcatid({commit},{jobcat_id}){
            commit('SET_JOBCAT_ID', jobcat_id);
        },
        setdealtypeid({commit},{dealtype}){
            commit('SET_DEALTYPE_ID', dealtype);
        },
        setsearchy({commit},{searchy}){
            commit('SET_SEARCHY', searchy)
        },
        usersearch({commit}, {userSearch}){
            commit('SET_USER_SEARCH',userSearch);
        },
        logout({ commit }) {
            commit('CLEAR_USER');
        },
        removeid({ commit }) {
            commit('CLEAR_ID');
        },

    },
});
// Retrieve stored state from local storage
const userToken = localStorage.getItem('userToken');
const userId = JSON.parse(localStorage.getItem('userId'));
// const city_id = localStorage.getItem('city_id');
// const subcat_id = localStorage.getItem('subcat_id');
const userData = JSON.parse(localStorage.getItem('userData'));
const userSearch = JSON.parse(localStorage.getItem('userSearch'));

if (userToken) {
    store.commit('SET_USER_TOKEN', userToken);
}
if (userId) {
    store.commit('SET_USER_ID', userId);
}

if (userData) {
    store.commit('SET_USER_DATA', userData);
}
if(userSearch) {
    store.commit('SET_USER_SEARCH',userSearch);
}
// if(city_id) {
//     store.commit('SET_CITY_ID',city_id);
// }
// if(subcat_id){
//     store.commit('SET_SUBCAT_ID',subcat_id);
// }
export default store;

