<template>
  <div>
    <div v-if="this.$route.matched.some(route => route.path.includes('/admin')) ||
               this.$route.matched.some(route => route.path.includes('/business/')) ||
               this.$route.matched.some(route => route.path.includes('/user/')) ||
               this.$route.matched.some(route => route.path.includes('/reseller'))">
    </div>
    <div v-else class="footer">
      <v-container>
        <v-row class="align-items-center msocial">
          <v-col cols="12" md="6" class="align-items-center d-flex justify-content-between justify-content-sm-start py-2">
            <!-- <span class="fs-4 fw-bold">Follow Bizlx</span> -->
            <div class="social align-items-center d-flex">
              <a href="https://www.facebook.com/bizlx" class="ms-1">
                <img src="@/assets/facebook-logo.png" width="35" alt="facebook" title="Facebook">
              </a>
              <a href="https://twitter.com/bizlx_" class="ms-1">
                <img src="@/assets/twitter-logo.png" width="35" alt="twitter" title="Twitter">
              </a>
              <a href="https://www.instagram.com/bizlx_/" class="ms-1">
                <img src="@/assets/instagram-logo.png" width="35" alt="instagram" title="Instagram">
              </a>
              <a href="https://www.linkedin.com/company/bizlx/" class="ms-1">
                <img src="@/assets/linkedin-logo.png" width="35" alt="linkedin" title="LinkedIn">
              </a>
              <a href="https://www.youtube.com/@bizlxtv" class="ms-1">
                <img src="@/assets/youtube-logo.png" width="35" alt="Youtube" title="YouTube">
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.bizlxapp" target="_blank" class="ms-1 d-none d-sm-block">
                <img src="@/assets/google-play.png" width="120" alt="Android App">
              </a>
              <a href="#" target="_blank" class="ms-1 d-none d-sm-block">
                <img src="@/assets/apple.png" width="120" alt="IOS App">
              </a>
            </div>
          </v-col>
<!--          <v-col cols="12" md="6" v-if="this.$route.matched.some(route => route.path.includes('/businessprofile'))">-->
<!--            <span class="fs-6 fw-bold">Embed Code :</span>-->
<!--            <router-link to="/jobs/by-city"><v-btn small color="primary" class="ms-2">Jobs</v-btn></router-link>-->
<!--            <router-link to="/deals/all"><v-btn small color="primary" class="ms-2">Hot Deals</v-btn></router-link>-->
<!--            <router-link to="/deals/all"><v-btn small color="primary" class="ms-2">Reviews</v-btn></router-link>-->
<!--          </v-col>-->
        </v-row>
      </v-container>
      <div class="mobile d-none">
        <v-list class="mobmenu">
          <v-list-group v-for="item in items" :key="item.title"
                        v-model="item.active" :prepend-icon="item.action" no-action color="primary">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title" class="text-uppercase small fw-semibold"></v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item v-for="child in item.items" :key="child.title">
              <v-list-item-content>
                <v-list-item-title v-text="child.title" class="small"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
      </div>
      <div class="topfooter bg-light py-3" >
        <b-container>
          <b-row class="gy-2 row-cols-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-5 row-cols-xxl-6" >
            <b-col v-for="(footerdetail,i) in footerData" :key="i">
              <h6>{{footerdetail.columns_name}}</h6>
              <ul class="list-unstyled" v-for="(pages,i) in footerdetail.pages" :key="i">
                <li><router-link :to="pages.page_link">{{pages.page_name}}</router-link></li>
              </ul>
            </b-col>
          </b-row>
        </b-container>
      </div>

      <b-container fluid class="bg-dark d-none d-sm-block">
        <b-row class="text-center">
          <b-col class="text-white copyright">Powered By : Bizlx.com™ - Daily Market Update, Hot Deals, Shopping, Sales ©All Rights Reserved 2023</b-col>
        </b-row>
      </b-container>
<!--      <div class="mmenu sticky-bottom bgblu d-block d-sm-none d-flex justify-content-around align-items-center">-->
<!--        <router-link to="/">-->
<!--          <span class="text-light text-center">-->
<!--            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512"><path fill="white" d="M261.56 101.28a8 8 0 0 0-11.06 0L66.4 277.15a8 8 0 0 0-2.47 5.79L63.9 448a32 32 0 0 0 32 32H192a16 16 0 0 0 16-16V328a8 8 0 0 1 8-8h80a8 8 0 0 1 8 8v136a16 16 0 0 0 16 16h96.06a32 32 0 0 0 32-32V282.94a8 8 0 0 0-2.47-5.79Z"/><path fill="white" d="m490.91 244.15l-74.8-71.56V64a16 16 0 0 0-16-16h-48a16 16 0 0 0-16 16v32l-57.92-55.38C272.77 35.14 264.71 32 256 32c-8.68 0-16.72 3.14-22.14 8.63l-212.7 203.5c-6.22 6-7 15.87-1.34 22.37A16 16 0 0 0 43 267.56L250.5 69.28a8 8 0 0 1 11.06 0l207.52 198.28a16 16 0 0 0 22.59-.44c6.14-6.36 5.63-16.86-.76-22.97Z"/></svg>-->
<!--          </span>Home</router-link>-->
<!--        <router-link to="/mywishlist"><v-icon class="text-light" size="24px">mdi-clipboard-text</v-icon>Wishlist</router-link>-->
<!--        <v-icon class="text-light mid" size="48px">mdi-microsoft-xbox-controller-menu</v-icon>-->
<!--        <router-link to="/user/login"><v-icon class="text-light" size="24px">mdi-account-tie</v-icon>Account</router-link>-->
<!--        <a href="#"><v-icon class="text-light" size="24px">mdi-phone</v-icon>Contact</a>-->
<!--      </div>-->
    </div>
  </div>

</template>

<script>
import axios from 'axios';
export default {
  name: "FooterView",
  data: () => ({
    items: [
      {action: 'mdi-file-document',
        items: [
            { title: 'Home' },
            { title: 'Blog' },
            { title: 'Contact' }
        ],
        title: 'General Info',
      },
      {
        action: 'mdi-octagram',
        items: [
          { title: 'Payment' },
          { title: 'Terms of Use' },
          { title: 'Privacy Policy' },
        ],
        title: 'Resources',
      },
      {
        action: 'mdi-account-cog',
        items: [
            { title: 'Login' },
            { title: 'Register' }
        ],
        title: 'Reseller',
      },
      {
        action: 'mdi-view-carousel',
        items: [
            { title: 'Login' },
            { title: 'Register' }
        ],
        title: 'Business Listing',
      },
      
    ],
    footerData:[],
  }),

  created() {
        this.initialize();
  },

  methods: {
        initialize() {
          axios.get("footer/details").then( (resp) => {
            this.footerData = resp.data.footer_details;
          });   
        },
    },
}
</script>

<style scoped>
.mmenu{
  padding:4px 0;
}
.mmenu a{display: grid;font-size: 14px;color: #fff}
.router-link-active {
  color: #1976d2;
}
.mmenu a svg{font-size: 24px}
.topfooter ul {
  padding-left: 0 !important;
  font-size: 15px;
}
.topfooter ul li {margin-bottom: 10px;}
.topfooter h6 {text-transform: uppercase;font-weight: 600;}
.copyright{font-size: 12px;}
.topfooter a{color: rgba(0, 0, 0, 0.87);}
@media (max-width: 576px) {
  .msocial{ background-color: #f1f2f3;}
  .msocial .fs-4 {color: #000;}
}
</style>