<template>
  <div>
    <div class="mobile">
      <carousel v-if="jobs.length > 0" :autoplay="false" :margin="10" :items="6" :dots="false" :slideBy="1" :loop="true"
              :navText="['‹','›']"
              :responsive = "{0:{items:4},576:{items:3},768:{items:4},1200:{items:6}}"
              class="find-job">
      <div v-for="job in jobs" :key="job.id" class="item text-center">
        <router-link class="link-dark" :to="{ name: 'allcatjobs', params: {id: job.id, job_cat_slug: job.job_cat_slug }}">
          <img :src=url+job.job_img_url :alt=job.job_cat_name class="img-fluid">
          <p class="small job-category">{{job.job_cat_name}}</p>
        </router-link>
      </div>
    </carousel>
    </div>
    <div class="desktop">
      <carousel v-if="jobs.length > 0" :autoplay="false" :margin="30" :items="6" :dots="false" :slideBy="1" :loop="true"
              :navText="['‹','›']"
              :responsive = "{0:{items:4},576:{items:4},768:{items:4},992:{items:5},1200:{items:6}}"
              class="find-job">

      <div v-for="job in jobs" :key="job.id" class="item text-center">
        <router-link class="link-dark text-decoration-none" :to="{ name: 'allcatjobs', params: {id: job.id, job_cat_slug: job.job_cat_slug }}">
          <img :src=url+job.job_img_url :alt=job.job_cat_name class="img-fluid">
          <p class="small job-category">{{job.job_cat_name}}</p>
        </router-link>
      </div>
    </carousel>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";
import axios from "axios";
export default {
  name: "HomeJobs",
  components:{carousel},
  data (){
    return{
      url:'https://bizlx.itechvision.in',
      jobs:[],
    }
  },
  mounted() {
    this.getJobcategory();
  },
  methods:{
    getJobcategory() {
      axios.get("jobcategory")
          .then((resp) =>{
            this.jobs =  resp.data.jobcategories;
          });
    },
  }
}
</script>

<style scoped>
.find-job img.img-fluid {
  width: 64px;
  margin: 0 auto;
}
div.find-job p.job-category {
  text-align: center;
  height: 42px;
  overflow: hidden;
  margin-bottom: 0;
}
@media (max-width: 576px){
  .find-job img.img-fluid {
    width: 32px;
    margin: 0 auto;
  }
  div.find-job p.job-category {
    text-align: center;
    height: 36px;
    overflow: hidden;
    font-size: 12px;
  }
}
</style>