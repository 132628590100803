<template>
  <v-app class="bg-white">
    <v-main>
      <AdminBar />
      <transition @before-enter="scrollTop" mode="out-in" appear>
        <router-view></router-view>
      </transition>
      <footer-view></footer-view>
    </v-main>
  </v-app>
</template>

<script>
import AdminBar from './views/admin/layout/AdminBar.vue';
import FooterView from "@/views/FooterView";
import axios from "axios";
export default {
  name: "App",
  components: {FooterView, AdminBar },
  data(){
      return{
        user:null,
      }
  },
  async created() {
      const response = await axios.get('user');
      this.$store.dispatch('user',response.data);
  },
  methods: {
    scrollTop(){
      document.getElementById('app').scrollIntoView();
    },
  }
};
</script>
<style>
:root{
  --cred:#fd0606;
  --cblu:#0165a3;
}
.mcontact{display: none;}
.mcontact.deb {display: block;text-align:center;width: 48px !important;}
.mcontact.deb a {
  padding-top: 15px;
  display: block;
}
.bcenter .v-image__image {
    background-position: bottom center !important;
}
del{color:#000;}
#call {
  color: var(--cred);
  font-weight: 600;
  margin-bottom:6px;
}
.text-justify img {max-width: 100% !important;}
.mdetails{padding-left:20px !important;}
.cred {color:var(--cred) !important;}
.cblu {color:var(--cblu) !important;}
.bgred {background-color:var(--cred) !important;}
.bgblu {background-color:var(--cblu) !important;}
.bbred {border-bottom: 1px solid var(--cred)}
.v-btn{
  text-transform: capitalize !important;
  letter-spacing: inherit !important;}
  .mtabs .nav-item a {
    color: #000;
    font-size: 12px;
}
.mtab .nav-item a {
  color: #000;
  }
.v-application ul, .v-application ol {
  padding-left: 0;
  }
.boottabs {
  position: relative;
  margin-left: 200px;
  }
#sel1::placeholder, #sel2::placeholder {
  color: #000000 !important;
  opacity: 1;
}
#sel14::placeholder {
  color: #000 !important;
  opacity: 1;
}
.sel1 .v-input__slot::before {
  border: 0 !important;
}
.sel2 .v-input__slot::before {
  border: 0 !important;
}
.mobmenu  .v-list-group__header__prepend-icon i {
  color: var(--cblu) !important;
}
.mobmenu .v-icon.mdi-chevron-down {
  background: var(--cblu);
  border-radius: 50%;
  color: #fff;
  font-size: 16px;
}
.mobile .owl-theme .owl-nav {
  margin-top: 0px;
}
.owl-theme .owl-nav [class*="owl-"] {
  color: #000 !important;
  font-size: 21px !important;
  padding: 0 !important;
  background: #f1f2f3 !important;
  border-radius: 50% !important;
  width: 33px;
  height: 33px;
}
.owl-nav .owl-prev, .owl-nav .owl-next {
  display: inline-flex !important;
  justify-content: center;
  line-height: 1;
  align-items: center;
}
.v-application  button.cred {
  background-color: var(--cred) !important;
  border-color: var(--cred) !important;
  color:#fff !important;
}
 .v-btn:not(.v-btn--outlined).cred {
  color: #FFFFFF;
}
a {text-decoration: none !important;}
.owl-item .item {margin-bottom: 10px; padding: 1px;}
.row + .row {
    margin-top: 0px;
}
.v-icon.mdi.mdi-map-marker.theme--light {
  color: var(--cblu);
}
.container-fluid.admin + .footer, .admin + div .footer {
  display: none;
}

.popmodal .close {
  font-size: 1.5rem;
  font-weight: bold;
  opacity: 1.7;
  font-size: 30px;
  }
.popmodal .modal-dialog {
  max-width: 300px; /* set the maximum width of the modal dialog */
  width: 60%; /* set the actual width of the modal dialog */
  }
.popmodal .modal-header {
  padding: 5px;
  }
.mr112{margin-top: 112px}
.close {
  font-size: 30px;
  font-weight: bold;
  opacity: 1;
  }
#model-gallery1 .modal-header {
  padding: 0 1rem;
}

.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 50% !important;
  }

.modal-title {
	line-height: 1;
	font-weight: 600;
}
#applynow .modal-content{
  border-radius: 15px !important;
  }
@media (max-width: 576px) {
  .popmodal .modal-dialog {
    max-width: 300px;
    width: 60%;
    margin-left: 80px;
    }
  .v-alert__icon {
    align-self: auto !important;
    }
  html{font-size: 14px;}

  .owl-theme .owl-nav [class*="owl-"] {
    color: var(--cblu) !important;
    font-size: 32px !important;
    padding: 0 !important;
    background: #fff !important;
    border-radius: 50% !important;
    width: 33px;
    /*height: 33px;*/
    font-weight: 800;
  }
  .vsearch.mobile .owl-theme .owl-nav [class*="owl-"] {
    font-size: 29px !important;
    background: transparent !important;
    font-weight: 800;
  }
  .vsearch.mobile .owl-nav{
    position:absolute;
    right: 0;
    top:-29px;
  }
}
@media(max-width: 576px){
  .desktop{display: none !important;}
  .v-expansion-panel-content>div {padding: 0;}
}
@media(min-width: 576.1px){.mobile{display: none !important;}
}
/*@media(min-width: 576.1px) and (max-width: 1199.9px){*/
/*  .desktop .ipad {display: block !important;}*/
/*}*/
@media (min-width: 991.9px) {
  .ipad {display: none!important;}
}
@media (max-width: 991.9px){
  .m992{display:none !important;}
}
@media(max-width: 576px) {
  .v-speed-dial--direction-bottom .v-speed-dial__list {
    flex-direction: unset !important;
    top: 40% !important;
    }
  .v-speed-dial--direction-top .v-speed-dial__list, .v-speed-dial--direction-bottom .v-speed-dial__list {
    left: -66px !important;
    width: 100%;
    }
  .v-btn--outlined {
    border-width: 2px;
  }
  #accordion-4 label {
    display:flex;
    justify-content: center;
  }
  #accordion-4 label img {
    min-height: 110px;
    max-height: 110px;
    aspect-ratio: 3/2;
    object-fit: contain;
  }
  #accordion-4 label + .h6 > div, #accordion-4 label + div > div {
    max-height: 16.8px;
    overflow: hidden;
    line-height: 16.8px;
  }
  }
@media(min-width: 576px) {
  .v-btn:not(.v-btn--round).v-size--small {
    min-width: 100px !important;
    }
  .v-btn:not(.v-btn--round).v-size--small.go {
    min-width: 50px !important;
    }
  #accordion-4 label {
    display:flex;
    justify-content: center;
  }

  #accordion-4 label img {
    max-height: 175px;
    min-height: 175px;
    aspect-ratio: 3/2;
    object-fit: contain;
  }
  #accordion-4 label + .h6 > div, #accordion-4 label + div > div {
    max-height: 19.2px;
    overflow: hidden;
    line-height: 19.2px;
  }
  /*.cole .img-fluid.top-listing {*/
  /*  min-height: 80vh;*/
  /*}*/
  }
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 90% !important;
}
.srch .v-snack__wrapper.v-sheet.theme--dark {
  position: absolute !important;
  top: 33% !important;
}
.v-menu__content {max-height: 200px !important;}
#accordion-2 .card-body p .cold2 {
  max-height: 300px;
  overflow: auto;
  padding:0 16px !important;
}
</style>